/**
 * Admin Menu Reducer
 */

import {
  ADMIN_MENU_REQUEST,
  ADMIN_MENU_SUCCESS,
  ADMIN_MENU_ERROR,
  ADMIN_MENU_UPDATE_ROUTES,
  AIRLINES_REQUEST,
  AIRLINES_SUCCESS,
  AIRLINES_ERROR,
  AUSPOST_LABEL_LAYOUTS_REQUEST,
  AUSPOST_LABEL_LAYOUTS_SUCCESS,
  AUSPOST_LABEL_LAYOUTS_ERROR,
  AUSPOST_PRODUCTS_REQUEST,
  AUSPOST_PRODUCTS_SUCCESS,
  AUSPOST_PRODUCTS_ERROR,
  BUYING_PERIODS_REQUEST,
  BUYING_PERIODS_SUCCESS,
  BUYING_PERIODS_ERROR,
  CLIENTS_REQUEST,
  CLIENTS_SUCCESS,
  CLIENTS_ERROR,
  CLOUDINARY_LIST_REQUEST,
  CLOUDINARY_LIST_SUCCESS,
  CLOUDINARY_LIST_ERROR,
  CLOUDINARY_LORI_LIST_REQUEST,
  CLOUDINARY_LORI_LIST_SUCCESS,
  CLOUDINARY_LORI_LIST_ERROR,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_ERROR,
  COUNTRY_REGIONS_REQUEST,
  COUNTRY_REGIONS_SUCCESS,
  COUNTRY_REGIONS_ERROR,
  CUSTOMER_ACCOUNTS_REQUEST,
  CUSTOMER_ACCOUNTS_SUCCESS,
  CUSTOMER_ACCOUNTS_ERROR,
  CUSTOMER_REPRESENTATIVES_REQUEST,
  CUSTOMER_REPRESENTATIVES_SUCCESS,
  CUSTOMER_REPRESENTATIVES_ERROR,
  DESTINATIONS_REQUEST,
  DESTINATIONS_SUCCESS,
  DESTINATIONS_ERROR,
  EMAIL_TEMPLATES_REQUEST,
  EMAIL_TEMPLATES_ERROR,
  EMAIL_TEMPLATES_SUCCESS,
  EVENT_TIERS_REQUEST,
  EVENT_TIERS_ERROR,
  EVENT_TIERS_SUCCESS,
  PROGRAM_SETTINGS_REQUEST,
  PROGRAM_SETTINGS_ERROR,
  PROGRAM_SETTINGS_SUCCESS,
  PRODUCTS_REQUEST,
  PRODUCTS_ERROR,
  PRODUCTS_SUCCESS,
  REWARDS_REQUEST,
  REWARDS_ERROR,
  REWARDS_SUCCESS,
  REWARD_CATEGORIES_REQUEST,
  REWARD_CATEGORIES_ERROR,
  REWARD_CATEGORIES_SUCCESS,
  REWARD_SUPPLIERS_REQUEST,
  REWARD_SUPPLIERS_SUCCESS,
  REWARD_SUPPLIERS_ERROR,
  ROLE_DEFINITIONS_REQUEST,
  ROLE_DEFINITIONS_SUCCESS,
  ROLE_DEFINITIONS_ERROR,
  UNIT_OF_MEASURE_REQUEST,
  UNIT_OF_MEASURE_SUCCESS,
  UNIT_OF_MEASURE_ERROR,
  USER_POINTS_BALANCE_REQUEST,
  USER_POINTS_BALANCE_SUCCESS,
  USER_POINTS_BALANCE_ERROR,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_ERROR,
  USER_STATUS_REQUEST,
  USER_STATUS_SUCCESS,
  USER_STATUS_ERROR,
} from './constants';

export const initialState = {
  adminMenu: null,
  airlines: { data: undefined, requestDetails: undefined },
  buyingPeriods: { data: undefined, requestDetails: undefined },
  clients: { data: undefined, requestDetails: undefined },
  cloudinaryList: { data: undefined, tag: undefined },
  cloudinaryLoriList: { data: undefined, tag: undefined },
  countries: { data: undefined, requestDetails: undefined },
  countryRegions: { data: undefined, requestDetails: undefined },
  customerAccounts: { data: undefined, userId: undefined },
  destinations: { data: undefined, requestDetails: undefined },
  program: undefined,
  rewardCategories: { data: undefined, requestDetails: undefined },
  rewardSuppliers: { data: undefined, requestDetails: undefined },
  roleDefinitions: null,
  routes: (localStorage.routes && JSON.parse(localStorage.routes)) || undefined,
  unitOfMeasure: undefined,
};

function webAppReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_MENU_ERROR:
      return {
        ...state,
        adminMenu: initialState.adminMenu,
      };
    case ADMIN_MENU_REQUEST:
      if (action.refresh) {
        return state;
      }

      return {
        ...state,
        adminMenu: [],
      };
    case ADMIN_MENU_SUCCESS:
      return {
        ...state,
        adminMenu: action.response,
      };
    case ADMIN_MENU_UPDATE_ROUTES:
      return {
        ...state,
        routes: action.response,
      };
    case BUYING_PERIODS_ERROR:
      return {
        ...state,
        buyingPeriods: initialState.buyingPeriods,
      };
    case BUYING_PERIODS_REQUEST:
      return {
        ...state,
        buyingPeriods: { data: [], requestDetails: {} },
      };
    case BUYING_PERIODS_SUCCESS:
      return {
        ...state,
        buyingPeriods: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case CLIENTS_ERROR:
      return {
        ...state,
        clients: initialState.clients,
      };
    case CLIENTS_REQUEST:
      return {
        ...state,
        clients: {
          // If it's a refresh, don't reset clients for better perceived performance
          data: action.refresh ? state.clients.data : [],
          requestDetails: {
            requestType: action.requestType,
            value: action.value,
          },
          lastUpdated: state.clients.lastUpdated,
        },
      };
    case CLIENTS_SUCCESS:
      return {
        ...state,
        clients: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case CLOUDINARY_LIST_REQUEST:
      return {
        ...state,
        cloudinaryList: {
          data:
            !action.refresh || action.tag !== state.cloudinaryList.tag
              ? []
              : state.cloudinaryList.data,
          tag: state.cloudinaryList.tag || action.tag,
          lastUpdated: state.cloudinaryList.lastUpdated,
        },
      };
    case CLOUDINARY_LIST_SUCCESS:
      return {
        ...state,
        cloudinaryList: {
          data: action.response,
          tag: action.tag,
          lastUpdated: action.lastUpdated,
        },
      };
    case CLOUDINARY_LIST_ERROR:
      return {
        ...state,
        cloudinaryList: initialState.cloudinaryList,
      };
    case CLOUDINARY_LORI_LIST_REQUEST:
      return {
        ...state,
        cloudinaryLoriList: {
          data:
            action.tag !== state.cloudinaryLoriList.tag
              ? []
              : state.cloudinaryLoriList.data,
          tag: state.cloudinaryLoriList.tag || action.tag,
          lastUpdated: state.cloudinaryLoriList.lastUpdated,
        },
      };
    case CLOUDINARY_LORI_LIST_SUCCESS:
      return {
        ...state,
        cloudinaryLoriList: {
          data: action.response,
          tag: action.tag,
          lastUpdated: action.lastUpdated,
        },
      };
    case CLOUDINARY_LORI_LIST_ERROR:
      return {
        ...state,
        cloudinaryLoriList: initialState.cloudinaryLoriList,
      };
    case AIRLINES_ERROR:
      return {
        ...state,
        airlines: initialState.airlines,
      };
    case AIRLINES_REQUEST:
      return {
        ...state,
        airlines: {
          // If it's a refresh, don't reset airlines for better perceived performance
          data: action.refresh ? state.airlines.data : [],
          requestDetails: action.requestDetails,
          lastUpdated: state.airlines.lastUpdated,
        },
      };
    case AIRLINES_SUCCESS:
      return {
        ...state,
        airlines: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case DESTINATIONS_ERROR:
      return {
        ...state,
        destinations: initialState.destinations,
      };
    case DESTINATIONS_REQUEST:
      return {
        ...state,
        destinations: {
          // If it's a refresh, don't reset destinations for better perceived performance
          data: action.refresh ? state.destinations.data : [],
          requestDetails: action.requestDetails,
          lastUpdated: state.destinations.lastUpdated,
        },
      };
    case DESTINATIONS_SUCCESS:
      return {
        ...state,
        destinations: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case AUSPOST_LABEL_LAYOUTS_ERROR:
      return {
        ...state,
        labelLayouts: initialState.labelLayouts,
      };
    case AUSPOST_LABEL_LAYOUTS_REQUEST:
      return {
        ...state,
        labelLayouts: {
          // If it's a refresh, don't reset layouts for better perceived performance
          data: action.refresh ? state.labelLayouts.data : [],
          requestDetails: action.requestDetails,
          lastUpdated: state.lastUpdated,
        },
      };
    case AUSPOST_LABEL_LAYOUTS_SUCCESS:
      return {
        ...state,
        labelLayouts: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case AUSPOST_PRODUCTS_ERROR:
      return {
        ...state,
        ausPostProducts: initialState.ausPostProducts,
      };
    case AUSPOST_PRODUCTS_REQUEST:
      return {
        ...state,
        ausPostProducts: {
          // If it's a refresh, don't reset products for better perceived performance
          data: action.refresh ? state.ausPostProducts.data : [],
          requestDetails: action.requestDetails,
          lastUpdated: state.lastUpdated,
        },
      };
    case AUSPOST_PRODUCTS_SUCCESS:
      return {
        ...state,
        ausPostProducts: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case COUNTRIES_ERROR:
      return {
        ...state,
        countries: initialState.countries,
      };
    case COUNTRIES_REQUEST:
      return {
        ...state,
        countries: {
          // If it's a refresh, don't reset countries for better perceived performance
          data: action.refresh ? state.countries.data : [],
          requestDetails: action.requestDetails,
          lastUpdated: state.countries.lastUpdated,
        },
      };
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case COUNTRY_REGIONS_ERROR:
      return {
        ...state,
        countryRegions: initialState.countryRegions,
      };
    case COUNTRY_REGIONS_REQUEST:
      return {
        ...state,
        countryRegions: {
          // If it's a refresh, don't reset countryRegions for better perceived performance
          data: action.refresh ? state.countryRegions.data : [],
          requestDetails: action.requestDetails,
          lastUpdated: state.countryRegions.lastUpdated,
        },
      };
    case COUNTRY_REGIONS_SUCCESS:
      return {
        ...state,
        countryRegions: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case CUSTOMER_ACCOUNTS_ERROR:
      return {
        ...state,
        customerAccounts: initialState.customerAccounts,
      };
    case CUSTOMER_ACCOUNTS_REQUEST:
      if (action.refresh) {
        // Do not reset accounts for simple refresh
        return state;
      }

      return {
        ...state,
        customerAccounts: { data: [], userId: null },
      };
    case CUSTOMER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        customerAccounts: {
          data: action.response,
          userId: action.userId,
        },
      };
    case CUSTOMER_REPRESENTATIVES_ERROR:
      return {
        ...state,
        customerRepresentatives: initialState.customerRepresentatives,
      };
    case CUSTOMER_REPRESENTATIVES_REQUEST:
      if (action.refresh) {
        // Do not reset accounts for simple refresh
        return state;
      }

      return {
        ...state,
        customerRepresentatives: { data: [], userId: null },
      };
    case CUSTOMER_REPRESENTATIVES_SUCCESS:
      return {
        ...state,
        customerRepresentatives: {
          data: action.response,
          userId: action.userId,
        },
      };
    case EMAIL_TEMPLATES_ERROR:
      return {
        ...state,
        emailTemplates: initialState.emailTemplates,
      };
    case EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        emailTemplates: { data: [], requestDetails: {} },
      };
    case EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case EVENT_TIERS_ERROR:
      return {
        ...state,
        eventTiers: initialState.eventTiers,
      };
    case EVENT_TIERS_REQUEST:
      return {
        ...state,
        eventTiers: { data: [], requestDetails: {} },
      };
    case EVENT_TIERS_SUCCESS:
      return {
        ...state,
        eventTiers: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case PROGRAM_SETTINGS_REQUEST:
      if (action.refresh && state.program) {
        return state;
      }

      return {
        ...state,
        program: {},
      };
    case PROGRAM_SETTINGS_SUCCESS:
      return {
        ...state,
        program: action.response,
      };
    case PROGRAM_SETTINGS_ERROR:
      return {
        ...state,
        program: initialState.program,
      };
    case PRODUCTS_REQUEST: {
      if (action.refresh) {
        // Do not reset products for simple refresh
        return state;
      }

      return {
        ...state,
        products: {},
      };
    }
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          data: action.response,
          lastUpdated: action.lastUpdated,
        },
      };
    case PRODUCTS_ERROR: {
      return {
        ...state,
        products: action.reset ? initialState.products : {},
      };
    }
    case REWARDS_ERROR:
      return {
        ...state,
        rewards: initialState.rewards,
      };
    case REWARDS_REQUEST:
      return {
        ...state,
        rewards: { data: [], requestDetails: {} },
      };
    case REWARDS_SUCCESS:
      return {
        ...state,
        rewards: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case REWARD_CATEGORIES_ERROR:
      return {
        ...state,
        rewardCategories: initialState.rewardCategories,
      };
    case REWARD_CATEGORIES_REQUEST:
      return {
        ...state,
        rewardCategories: { data: [], requestDetails: {} },
      };
    case REWARD_CATEGORIES_SUCCESS:
      return {
        ...state,
        rewardCategories: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case REWARD_SUPPLIERS_ERROR:
      return {
        ...state,
        rewardSuppliers: initialState.rewardSuppliers,
      };
    case REWARD_SUPPLIERS_REQUEST:
      return {
        ...state,
        rewardSuppliers: { data: [], requestDetails: {} },
      };
    case REWARD_SUPPLIERS_SUCCESS:
      return {
        ...state,
        rewardSuppliers: {
          data: action.response,
          requestDetails: action.requestDetails,
          lastUpdated: action.lastUpdated,
        },
      };
    case ROLE_DEFINITIONS_REQUEST:
      return {
        ...state,
        roleDefinitions: {},
      };
    case ROLE_DEFINITIONS_SUCCESS:
      return {
        ...state,
        roleDefinitions: {
          data: action.response,
          lastUpdated: action.lastUpdated,
        },
      };
    case ROLE_DEFINITIONS_ERROR:
      return {
        ...state,
        roleDefinitions: initialState.roleDefinitions,
      };
    case UNIT_OF_MEASURE_ERROR:
      return {
        ...state,
        unitOfMeasure: initialState.unitOfMeasure,
      };
    case UNIT_OF_MEASURE_REQUEST:
      if (action.refresh && state.unitOfMeasure) {
        return state;
      }

      return {
        ...state,
        unitOfMeasure: [],
      };
    case UNIT_OF_MEASURE_SUCCESS:
      return {
        ...state,
        unitOfMeasure: action.response,
      };
    case USER_POINTS_BALANCE_REQUEST: {
      if (action.refresh) {
        // Do not reset userPointsBalance for simple refresh
        return {
          ...state,
          userPointsBalance: {
            ...state.userPointsBalance,
            data: undefined,
          },
        };
      }

      return {
        ...state,
        userPointsBalance: {},
      };
    }
    case USER_POINTS_BALANCE_SUCCESS:
      return {
        ...state,
        userPointsBalance: {
          data: action.response,
          userId: action.userId,
        },
      };
    case USER_POINTS_BALANCE_ERROR:
      return {
        ...state,
        userPointsBalance: {
          data: null,
          userId: action.userId,
        },
      };
    case USER_PROFILE_REQUEST: {
      if (action.refresh) {
        // Do not reset statusDetails for simple refresh
        return {
          ...state,
          profileDetails: {
            ...state.profileDetails,
            data: undefined,
          },
        };
      }

      return {
        ...state,
        profileDetails: {},
      };
    }
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        profileDetails: {
          data: action.response,
          userId: action.userId,
        },
      };
    case USER_PROFILE_ERROR:
      return {
        ...state,
        profileDetails: {
          data: null,
          userId: action.userId,
        },
      };
    case USER_STATUS_REQUEST:
      if (action.refresh) {
        // Do not reset userStatusDetails on refresh
        return state;
      }

      return {
        ...state,
        userStatusDetails: {},
      };
    case USER_STATUS_SUCCESS:
      return {
        ...state,
        userStatusDetails: {
          data: action.response,
          userId: action.userId,
        },
      };
    case USER_STATUS_ERROR:
      return {
        ...state,
        userStatusDetails: {
          data: null,
          userId: action.userId,
        },
      };
    default:
      return state;
  }
}

export default webAppReducer;
