/**
 * Web App actions
 */

import {
  ADMIN_MENU_REQUEST,
  ADMIN_MENU_SUCCESS,
  ADMIN_MENU_ERROR,
  ADMIN_MENU_UPDATE_ROUTES,
  AIRLINES_REQUEST,
  AIRLINES_SUCCESS,
  AIRLINES_ERROR,
  AUSPOST_LABEL_LAYOUTS_REQUEST,
  AUSPOST_LABEL_LAYOUTS_SUCCESS,
  AUSPOST_LABEL_LAYOUTS_ERROR,
  AUSPOST_PRODUCTS_REQUEST,
  AUSPOST_PRODUCTS_SUCCESS,
  AUSPOST_PRODUCTS_ERROR,
  BUYING_PERIODS_REQUEST,
  BUYING_PERIODS_SUCCESS,
  BUYING_PERIODS_ERROR,
  CLIENTS_REQUEST,
  CLIENTS_SUCCESS,
  CLIENTS_ERROR,
  CLOUDINARY_LIST_REQUEST,
  CLOUDINARY_LIST_SUCCESS,
  CLOUDINARY_LIST_ERROR,
  CLOUDINARY_LORI_LIST_REQUEST,
  CLOUDINARY_LORI_LIST_SUCCESS,
  CLOUDINARY_LORI_LIST_ERROR,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_ERROR,
  COUNTRY_REGIONS_REQUEST,
  COUNTRY_REGIONS_SUCCESS,
  COUNTRY_REGIONS_ERROR,
  CUSTOMER_ACCOUNTS_REQUEST,
  CUSTOMER_ACCOUNTS_SUCCESS,
  CUSTOMER_ACCOUNTS_ERROR,
  CUSTOMER_REPRESENTATIVES_REQUEST,
  CUSTOMER_REPRESENTATIVES_SUCCESS,
  CUSTOMER_REPRESENTATIVES_ERROR,
  DESTINATIONS_REQUEST,
  DESTINATIONS_SUCCESS,
  DESTINATIONS_ERROR,
  EMAIL_TEMPLATES_REQUEST,
  EMAIL_TEMPLATES_ERROR,
  EMAIL_TEMPLATES_SUCCESS,
  EVENT_TIERS_REQUEST,
  EVENT_TIERS_ERROR,
  EVENT_TIERS_SUCCESS,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_ERROR,
  PROGRAM_SETTINGS_REQUEST,
  PROGRAM_SETTINGS_SUCCESS,
  PROGRAM_SETTINGS_ERROR,
  REWARDS_REQUEST,
  REWARDS_ERROR,
  REWARDS_SUCCESS,
  REWARD_CATEGORIES_REQUEST,
  REWARD_CATEGORIES_ERROR,
  REWARD_CATEGORIES_SUCCESS,
  REWARD_SUPPLIERS_REQUEST,
  REWARD_SUPPLIERS_SUCCESS,
  REWARD_SUPPLIERS_ERROR,
  ROLE_DEFINITIONS_REQUEST,
  ROLE_DEFINITIONS_SUCCESS,
  ROLE_DEFINITIONS_ERROR,
  UNIT_OF_MEASURE_REQUEST,
  UNIT_OF_MEASURE_SUCCESS,
  UNIT_OF_MEASURE_ERROR,
  USER_POINTS_BALANCE_REQUEST,
  USER_POINTS_BALANCE_SUCCESS,
  USER_POINTS_BALANCE_ERROR,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_ERROR,
  USER_STATUS_REQUEST,
  USER_STATUS_SUCCESS,
  USER_STATUS_ERROR,
} from './constants';

/**
 * Admin Menu request - start the request saga
 *
 * @returns {object} An action object with a type of ADMIN_MENU_REQUEST
 */
export function adminMenuRequest(refresh) {
  return {
    type: ADMIN_MENU_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when adminMenuRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @returns {object}         An action object with a type of ADMIN_MENU_SUCCESS and the response
 */
export function adminMenuSuccess(response) {
  return {
    type: ADMIN_MENU_SUCCESS,
    response,
  };
}

/**
 * Dispatched when adminMenuRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of ADMIN_MENU_ERROR and the error
 */
export function adminMenuError(error) {
  return {
    type: ADMIN_MENU_ERROR,
    error,
  };
}

/**
 * Dispatched in combination with adminMenuSuccess in order to set up routes
 *
 * @param {object} response The response data
 * @returns {object}        An action object with a type of ADMIN_MENU_UPDATE_ROUTES and the response
 */
export function adminMenuUpdateRoutes(response) {
  return {
    type: ADMIN_MENU_UPDATE_ROUTES,
    response,
  };
}

/**
 * Buying Periods request - start the request saga
 *
 * @returns {object} An action object with a type of BUYING_PERIODS_REQUEST
 */
export function buyingPeriodsRequest(clientId, current) {
  return {
    type: BUYING_PERIODS_REQUEST,
    clientId,
    current,
  };
}

/**
 * Dispatched when buyingPeriodsRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of BUYING_PERIODS_SUCCESS and the response
 */
export function buyingPeriodsSuccess(response, requestDetails, lastUpdated) {
  return {
    type: BUYING_PERIODS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when buyingPeriodsRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of BUYING_PERIODS_ERROR and the error
 */
export function buyingPeriodsError(error) {
  return {
    type: BUYING_PERIODS_ERROR,
    error,
  };
}

/**
 * Clients request - start the request saga
 * @param {string} requestType Determines which endpoint to request
 * @param {string} value Value to pass to the endpoint (eg, Client Code)
 * @returns {object} An action object with a type of CLIENTS_REQUEST
 */
export function clientsRequest(requestType, value, refresh) {
  return {
    type: CLIENTS_REQUEST,
    requestType,
    value,
    refresh,
  };
}

/**
 * Dispatched when clientsRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of CLIENTS_SUCCESS and the response
 */
export function clientsSuccess(response, requestDetails, lastUpdated) {
  return {
    type: CLIENTS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when clientsRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of CLIENTS_ERROR and the error
 */
export function clientsError(error) {
  return {
    type: CLIENTS_ERROR,
    error,
  };
}

/**
 * Cloudinary list request - start the request saga
 * @param {string} tag Instruct which tag to fetch
 * @param {bool} refresh Keep current state while fetching
 * @param {array} newImages New images to add to the response if the endpoint version is missing them due to caching
 * @returns {object} An action object with a type of CLOUDINARY_LIST_REQUEST
 */
export function cloudinaryListRequest(tag, refresh, newImages) {
  return {
    type: CLOUDINARY_LIST_REQUEST,
    tag,
    refresh,
    newImages,
  };
}

/**
 * Dispatched when cloudinaryListRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of CLOUDINARY_LIST_SUCCESS and the response
 */
export function cloudinaryListSuccess(response, tag, lastUpdated) {
  return {
    type: CLOUDINARY_LIST_SUCCESS,
    response,
    tag,
    lastUpdated,
  };
}

/**
 * Dispatched when cloudinaryListRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of CLOUDINARY_LIST_ERROR and the error
 */
export function cloudinaryListError(error) {
  return {
    type: CLOUDINARY_LIST_ERROR,
    error,
  };
}

/**
 * Cloudinary Lori list request - start the request saga
 * @param {string} tag Instruct which tag to fetch
 * @returns {object} An action object with a type of CLOUDINARY_LORI_LIST_REQUEST
 */
export function cloudinaryLoriListRequest(tag) {
  return {
    type: CLOUDINARY_LORI_LIST_REQUEST,
    tag,
  };
}

/**
 * Dispatched when cloudinaryLoriListRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of CLOUDINARY_LORI_LIST_SUCCESS and the response
 */
export function cloudinaryLoriListSuccess(response, tag, lastUpdated) {
  return {
    type: CLOUDINARY_LORI_LIST_SUCCESS,
    response,
    tag,
    lastUpdated,
  };
}

/**
 * Dispatched when cloudinaryLoriListRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of CLOUDINARY_LORI_LIST_ERROR and the error
 */
export function cloudinaryLoriListError(error) {
  return {
    type: CLOUDINARY_LORI_LIST_ERROR,
    error,
  };
}

/**
 * Countries request - start the request saga
 * @returns {object} An action object with a type of COUNTRIES_REQUEST
 */
export function countriesRequest(refresh) {
  return {
    type: COUNTRIES_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when countriesRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of COUNTRIES_SUCCESS and the response
 */
export function countriesSuccess(response, requestDetails, lastUpdated) {
  return {
    type: COUNTRIES_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when countriesRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of COUNTRIES_ERROR and the error
 */
export function countriesError(error) {
  return {
    type: COUNTRIES_ERROR,
    error,
  };
}

/**
 * CountryRegions request - start the request saga
 * @returns {object} An action object with a type of COUNTRY_REGIONS_REQUEST
 */
export function countryRegionsRequest(refresh) {
  return {
    type: COUNTRY_REGIONS_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when countryRegionsRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of COUNTRY_REGIONS_SUCCESS and the response
 */
export function countryRegionsSuccess(response, requestDetails, lastUpdated) {
  return {
    type: COUNTRY_REGIONS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when countryRegionsRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of COUNTRY_REGIONS_ERROR and the error
 */
export function countryRegionsError(error) {
  return {
    type: COUNTRY_REGIONS_ERROR,
    error,
  };
}

/**
 * Airlines request - start the request saga
 * @returns {object} An action object with a type of AIRLINES_REQUEST
 */
export function airlinesRequest(refresh) {
  return {
    type: AIRLINES_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when airlinesRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of AIRLINES_SUCCESS and the response
 */
export function airlinesSuccess(response, requestDetails, lastUpdated) {
  return {
    type: AIRLINES_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when airlinesRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of AIRLINES_ERROR and the error
 */
export function airlinesError(error) {
  return {
    type: AIRLINES_ERROR,
    error,
  };
}

/**
 * Destinations request - start the request saga
 * @returns {object} An action object with a type of DESTINATIONS_REQUEST
 */
export function destinationsRequest(refresh) {
  return {
    type: DESTINATIONS_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when destinationsRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of DESTINATIONS_SUCCESS and the response
 */
export function destinationsSuccess(response, requestDetails, lastUpdated) {
  return {
    type: DESTINATIONS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when destinationsRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of DESTINATIONS_ERROR and the error
 */
export function destinationsError(error) {
  return {
    type: DESTINATIONS_ERROR,
    error,
  };
}

/**
 * AusPostLabelLayouts request - start the request saga
 * @returns {object} An action object with a type of AUSPOST_LABEL_LAYOUTS_REQUEST
 */
export function ausPostLabelLayoutsRequest(refresh) {
  return {
    type: AUSPOST_LABEL_LAYOUTS_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when ausPostLabelLayoutsRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of AUSPOST_LABEL_LAYOUTS_SUCCESS and the response
 */
export function ausPostLabelLayoutsSuccess(
  response,
  requestDetails,
  lastUpdated
) {
  return {
    type: AUSPOST_LABEL_LAYOUTS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when ausPostLabelLayoutsRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of AUSPOST_LABEL_LAYOUTS_ERROR and the error
 */
export function ausPostLabelLayoutsError(error) {
  return {
    type: AUSPOST_LABEL_LAYOUTS_ERROR,
    error,
  };
}

/**
 * AusPostProducts request - start the request saga
 * @returns {object} An action object with a type of AUSPOST_PRODUCTS_REQUEST
 */
export function ausPostProductsRequest(refresh) {
  return {
    type: AUSPOST_PRODUCTS_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when ausPostProductsRequest succeeds by the request saga
 *
 * @param {array} response The response data
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of AUSPOST_PRODUCTS_SUCCESS and the response
 */
export function ausPostProductsSuccess(response, requestDetails, lastUpdated) {
  return {
    type: AUSPOST_PRODUCTS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when ausPostProductsRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of AUSPOST_PRODUCTS_ERROR and the error
 */
export function ausPostProductsError(error) {
  return {
    type: AUSPOST_PRODUCTS_ERROR,
    error,
  };
}

/**
 * Customer Accounts request - start the request saga
 *
 * @returns {object} An action object with a type of CUSTOMER_ACCOUNTS_REQUEST
 */
export function customerAccountsRequest(userId, refresh) {
  return {
    type: CUSTOMER_ACCOUNTS_REQUEST,
    userId,
    refresh,
  };
}

/**
 * Dispatched when customerAccountsRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @returns {object}         An action object with a type of CUSTOMER_ACCOUNTS_SUCCESS and the response
 */
export function customerAccountsSuccess(response, userId) {
  return {
    type: CUSTOMER_ACCOUNTS_SUCCESS,
    response,
    userId,
  };
}

/**
 * Dispatched when customerAccountsRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of CUSTOMER_ACCOUNTS_ERROR and the error
 */
export function customerAccountsError(error) {
  return {
    type: CUSTOMER_ACCOUNTS_ERROR,
    error,
  };
}

/**
 * Customer Representatives request - start the request saga
 *
 * @returns {object} An action object with a type of CUSTOMER_REPRESENTATIVES_REQUEST
 */
export function customerRepresentativesRequest(userId, refresh) {
  return {
    type: CUSTOMER_REPRESENTATIVES_REQUEST,
    userId,
    refresh,
  };
}

/**
 * Dispatched when customerRepresentativesRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @returns {object}         An action object with a type of CUSTOMER_REPRESENTATIVES_SUCCESS and the response
 */
export function customerRepresentativesSuccess(response, userId) {
  return {
    type: CUSTOMER_REPRESENTATIVES_SUCCESS,
    response,
    userId,
  };
}

/**
 * Dispatched when customerRepresentativesRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of CUSTOMER_REPRESENTATIVES_ERROR and the error
 */
export function customerRepresentativesError(error) {
  return {
    type: CUSTOMER_REPRESENTATIVES_ERROR,
    error,
  };
}

/**
 * EmailTemplates request - start the request saga
 *
 * @param {bool} refresh If true, do not remove existing values before updating
 * @returns {object} An action object with a type of EMAIL_TEMPLATES_REQUEST
 */
export function emailTemplatesRequest(refresh) {
  return {
    type: EMAIL_TEMPLATES_REQUEST,
    refresh,
  };
}

/**
 * EmailTemplates Success - Dispatched when emailTemplatesRequest succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}        An action object with the type of EMAIL_TEMPLATES_SUCCESS and the response data
 */
export function emailTemplatesSuccess(response, requestDetails, lastUpdated) {
  return {
    type: EMAIL_TEMPLATES_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when emailTemplatesRequest fails
 *
 * @param {object} error The error as received from the API
 *
 * @returns {object}     An action object with a type of EMAIL_TEMPLATES_ERROR and the error
 */
export function emailTemplatesError(error) {
  return {
    type: EMAIL_TEMPLATES_ERROR,
    error,
  };
}

/**
 * EventTiers request - start the request saga
 *
 * @param {bool} refresh If true, do not remove existing values before updating
 * @returns {object} An action object with a type of EVENT_TIERS_REQUEST
 */
export function eventTiersRequest(refresh) {
  return {
    type: EVENT_TIERS_REQUEST,
    refresh,
  };
}

/**
 * EventTiers Success - Dispatched when eventTiersRequest succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}        An action object with the type of EVENT_TIERS_SUCCESS and the response data
 */
export function eventTiersSuccess(response, requestDetails, lastUpdated) {
  return {
    type: EVENT_TIERS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when eventTiersRequest fails
 *
 * @param {object} error The error as received from the API
 *
 * @returns {object}     An action object with a type of EVENT_TIERS_ERROR and the error
 */
export function eventTiersError(error) {
  return {
    type: EVENT_TIERS_ERROR,
    error,
  };
}

/**
 * Program settings request - start the request saga
 *
 * @returns {object} An action object with a type of PROGRAM_SETTINGS_REQUEST
 */
export function programSettingsRequest() {
  return {
    type: PROGRAM_SETTINGS_REQUEST,
  };
}

/**
 * Dispatched when programSettingsRequest succeeds by the request saga
 *
 * @param {object} response The response
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of PROGRAM_SETTINGS_SUCCESS and the response
 */
export function programSettingsSuccess(response, lastUpdated) {
  return {
    type: PROGRAM_SETTINGS_SUCCESS,
    response,
    lastUpdated,
  };
}

/**
 * Dispatched when programSettingsRequest fails
 *
 * @returns {object}     An action object with a type of PROGRAM_SETTINGS_ERROR and the error
 */
export function programSettingsError() {
  return {
    type: PROGRAM_SETTINGS_ERROR,
  };
}

/**
 * Products request - start the request saga
 *
 * @param {bool} refresh If true, do not remove existing values before updating
 * @returns {object} An action object with a type of PRODUCTS_REQUEST
 */
export function productsRequest(refresh) {
  return {
    type: PRODUCTS_REQUEST,
    refresh,
  };
}

/**
 * Products Success - Dispatched when productsRequest succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}        An action object with the type of PRODUCTS_SUCCESS and the response data
 */
export function productsSuccess(response, requestDetails, lastUpdated) {
  return {
    type: PRODUCTS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when productsRequest fails
 *
 * @param {object} error The error as received from the API
 *
 * @returns {object}     An action object with a type of PRODUCTS_ERROR and the error
 */
export function productsError(error) {
  return {
    type: PRODUCTS_ERROR,
    error,
  };
}

/**
 * Rewards request - start the request saga
 *
 * @param {bool} refresh If true, do not remove existing values before updating
 * @returns {object} An action object with a type of REWARDS_REQUEST
 */
export function rewardsRequest(requestType, refresh) {
  return {
    type: REWARDS_REQUEST,
    requestType,
    refresh,
  };
}

/**
 * Rewards Success - Dispatched when rewardsRequest succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}        An action object with the type of REWARDS_SUCCESS and the response data
 */
export function rewardsSuccess(response, requestDetails, lastUpdated) {
  return {
    type: REWARDS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when rewardsRequest fails
 *
 * @param {object} error The error as received from the API
 *
 * @returns {object}     An action object with a type of REWARDS_ERROR and the error
 */
export function rewardsError(error) {
  return {
    type: REWARDS_ERROR,
    error,
  };
}

/**
 * Reward categories request - start the request saga
 *
 * @param {bool} refresh If true, do not remove existing values before updating
 * @returns {object} An action object with a type of REWARD_CATEGORIES_REQUEST
 */
export function rewardCategoriesRequest(refresh) {
  return {
    type: REWARD_CATEGORIES_REQUEST,
    refresh,
  };
}

/**
 * Reward Categories Success - Dispatched when rewardCategoriesRequest succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}        An action object with the type of REWARD_CATEGORIES_SUCCESS and the response data
 */
export function rewardCategoriesSuccess(response, requestDetails, lastUpdated) {
  return {
    type: REWARD_CATEGORIES_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when rewardCategoriesRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of REWARD_CATEGORIES_ERROR and the error
 */
export function rewardCategoriesError(error) {
  return {
    type: REWARD_CATEGORIES_ERROR,
    error,
  };
}

/**
 * Reward suppliers request - start the request saga
 *
 * @param {bool} refresh If true, do not remove existing values before updating
 * @returns {object} An action object with a type of REWARD_SUPPLIERS_REQUEST
 */
export function rewardSuppliersRequest(refresh) {
  return {
    type: REWARD_SUPPLIERS_REQUEST,
    refresh,
  };
}

/**
 * Reward Suppliers Success - Dispatched when rewardSuppliersRequest succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @param {object} requestDetails The request details
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}        An action object with the type of REWARD_SUPPLIERS_SUCCESS and the response data
 */
export function rewardSuppliersSuccess(response, requestDetails, lastUpdated) {
  return {
    type: REWARD_SUPPLIERS_SUCCESS,
    response,
    requestDetails,
    lastUpdated,
  };
}

/**
 * Dispatched when rewardSuppliersRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of REWARD_SUPPLIERS_ERROR and the error
 */
export function rewardSuppliersError(error) {
  return {
    type: REWARD_SUPPLIERS_ERROR,
    error,
  };
}

/**
 * Role definitions request - start the request saga
 *
 * @returns {object} An action object with a type of ROLE_DEFINITIONS_REQUEST
 */
export function roleDefinitionsRequest() {
  return {
    type: ROLE_DEFINITIONS_REQUEST,
  };
}

/**
 * Dispatched when roleDefinitionsRequest succeeds by the request saga
 *
 * @param {object} response The response
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of ROLE_DEFINITIONS_SUCCESS and the response
 */
export function roleDefinitionsSuccess(response, lastUpdated) {
  return {
    type: ROLE_DEFINITIONS_SUCCESS,
    response,
    lastUpdated,
  };
}

/**
 * Dispatched when roleDefinitionsRequest fails
 *
 * @returns {object}     An action object with a type of ROLE_DEFINITIONS_ERROR and the error
 */
export function roleDefinitionsError() {
  return {
    type: ROLE_DEFINITIONS_ERROR,
  };
}

/**
 * Unit of Measure request - start the request saga
 *
 * @param {bool} refresh If true, do not remove existing values before updating
 * @returns {object} An action object with a type of UNIT_OF_MEASURE_REQUEST
 */
export function unitOfMeasureRequest(refresh) {
  return {
    type: UNIT_OF_MEASURE_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when unitOfMeasureRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @returns {object}         An action object with a type of UNIT_OF_MEASURE_SUCCESS and the response
 */
export function unitOfMeasureSuccess(response) {
  return {
    type: UNIT_OF_MEASURE_SUCCESS,
    response,
  };
}

/**
 * Dispatched when unitOfMeasureRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of UNIT_OF_MEASURE_ERROR and the error
 */
export function unitOfMeasureError(error) {
  return {
    type: UNIT_OF_MEASURE_ERROR,
    error,
  };
}

/**
 * User points balance request - start the request saga
 *
 * @returns {object} An action object with a type of USER_POINTS_BALANCE_REQUEST
 */
export function userPointsBalanceRequest(userId, refresh) {
  return {
    type: USER_POINTS_BALANCE_REQUEST,
    userId,
    refresh,
  };
}

/**
 * User Profile Success - Dispatched when userPointsBalanceRequest succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @param {string} userId User's ID
 * @returns {object}        An action object with the type of PROFILE_DETAILS and the response data
 */
export function userPointsBalanceSuccess(response, userId) {
  return {
    type: USER_POINTS_BALANCE_SUCCESS,
    response,
    userId,
  };
}

/**
 * Dispatched when userPointsBalanceRequest fails
 *
 * @param {object} error The error as received from the API
 * @param {string} userId User's ID
 * @returns {object}     An action object with a type of USER_POINTS_BALANCE_ERROR and the error
 */
export function userPointsBalanceError(error, userId) {
  return {
    type: USER_POINTS_BALANCE_ERROR,
    error,
    userId,
  };
}

/**
 * User profile request - start the request saga
 *
 * @returns {object} An action object with a type of USER_PROFILE_REQUEST
 */
export function userProfileRequest(userId, refresh) {
  return {
    type: USER_PROFILE_REQUEST,
    userId,
    refresh,
  };
}

/**
 * User Profile Success - Dispatched when userProfileRequest succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @param {string} userId User's ID
 * @returns {object}        An action object with the type of PROFILE_DETAILS and the response data
 */
export function userProfileSuccess(response, userId) {
  return {
    type: USER_PROFILE_SUCCESS,
    response,
    userId,
  };
}

/**
 * Dispatched when userProfileRequest fails
 *
 * @param {object} error The error as received from the API
 * @param {string} userId User's ID
 * @returns {object}     An action object with a type of USER_PROFILE_ERROR and the error
 */
export function userProfileError(error, userId) {
  return {
    type: USER_PROFILE_ERROR,
    error,
    userId,
  };
}

/**
 * User status request - start the request saga
 *
 * @returns {object} An action object with a type of USER_STATUS_REQUEST
 */
export function userStatusRequest(userId, refresh) {
  return {
    type: USER_STATUS_REQUEST,
    userId,
    refresh,
  };
}

/**
 * User Status Success - Dispatched when requestStatus succeeds by the request saga
 *
 * @param {object} response Data from the response
 * @returns {object}        An action object with the type of USER_STATUS_DETAILS and the response data
 */
export function userStatusSuccess(response, userId) {
  return {
    type: USER_STATUS_SUCCESS,
    response,
    userId,
  };
}

/**
 * Dispatched when userStatusRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of USER_STATUS_ERROR and the error
 */
export function userStatusError(error, userId) {
  return {
    type: USER_STATUS_ERROR,
    error,
    userId,
  };
}
