/**
 * WebApp constants
 */

export const ADMIN_MENU_REQUEST = 'local/WebApp/ADMIN_MENU_REQUEST';
export const ADMIN_MENU_SUCCESS = 'local/WebApp/ADMIN_MENU_SUCCESS';
export const ADMIN_MENU_ERROR = 'local/WebApp/ADMIN_MENU_ERROR';
export const ADMIN_MENU_UPDATE_ROUTES = 'local/WebApp/ADMIN_MENU_UPDATE_ROUTES';
export const AIRLINES_REQUEST = 'local/WebApp/AIRLINES_REQUEST';
export const AIRLINES_SUCCESS = 'local/WebApp/AIRLINES_SUCCESS';
export const AIRLINES_ERROR = 'local/WebApp/AIRLINES_ERROR';
export const AUSPOST_LABEL_LAYOUTS_REQUEST =
  'local/WebApp/AUSPOST_LABEL_LAYOUTS_REQUEST';
export const AUSPOST_LABEL_LAYOUTS_SUCCESS =
  'local/WebApp/AUSPOST_LABEL_LAYOUTS_SUCCESS';
export const AUSPOST_LABEL_LAYOUTS_ERROR =
  'local/WebApp/AUSPOST_LABEL_LAYOUTS_ERROR';
export const AUSPOST_PRODUCTS_REQUEST = 'local/WebApp/AUSPOST_PRODUCTS_REQUEST';
export const AUSPOST_PRODUCTS_SUCCESS = 'local/WebApp/AUSPOST_PRODUCTS_SUCCESS';
export const AUSPOST_PRODUCTS_ERROR = 'local/WebApp/AUSPOST_PRODUCTS_ERROR';
export const BUYING_PERIODS_REQUEST = 'local/WebApp/BUYING_PERIODS_REQUEST';
export const BUYING_PERIODS_SUCCESS = 'local/WebApp/BUYING_PERIODS_SUCCESS';
export const BUYING_PERIODS_ERROR = 'local/WebApp/BUYING_PERIODS_ERROR';
export const CLIENTS_REQUEST = 'local/WebApp/CLIENTS_REQUEST';
export const CLIENTS_SUCCESS = 'local/WebApp/CLIENTS_SUCCESS';
export const CLIENTS_ERROR = 'local/WebApp/CLIENTS_ERROR';
export const CLOUDINARY_LIST_REQUEST = 'local/WebApp/CLOUDINARY_LIST_REQUEST';
export const CLOUDINARY_LIST_SUCCESS = 'local/WebApp/CLOUDINARY_LIST_SUCCESS';
export const CLOUDINARY_LIST_ERROR = 'local/WebApp/CLOUDINARY_LIST_ERROR';
export const CLOUDINARY_LORI_LIST_REQUEST =
  'local/WebApp/CLOUDINARY_LORI_LIST_REQUEST';
export const CLOUDINARY_LORI_LIST_SUCCESS =
  'local/WebApp/CLOUDINARY_LORI_LIST_SUCCESS';
export const CLOUDINARY_LORI_LIST_ERROR =
  'local/WebApp/CLOUDINARY_LORI_LIST_ERROR';
export const COUNTRIES_REQUEST = 'local/WebApp/COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'local/WebApp/COUNTRIES_SUCCESS';
export const COUNTRIES_ERROR = 'local/WebApp/COUNTRIES_ERROR';
export const COUNTRY_REGIONS_REQUEST = 'local/WebApp/COUNTRY_REGIONS_REQUEST';
export const COUNTRY_REGIONS_SUCCESS = 'local/WebApp/COUNTRY_REGIONS_SUCCESS';
export const COUNTRY_REGIONS_ERROR = 'local/WebApp/COUNTRY_REGIONS_ERROR';
export const CUSTOMER_ACCOUNTS_REQUEST =
  'local/WebApp/CUSTOMER_ACCOUNTS_REQUEST';
export const CUSTOMER_ACCOUNTS_SUCCESS =
  'local/WebApp/CUSTOMER_ACCOUNTS_SUCCESS';
export const CUSTOMER_ACCOUNTS_ERROR = 'local/WebApp/CUSTOMER_ACCOUNTS_ERROR';
export const CUSTOMER_REPRESENTATIVES_REQUEST =
  'local/WebApp/CUSTOMER_REPRESENTATIVES_REQUEST';
export const CUSTOMER_REPRESENTATIVES_SUCCESS =
  'local/WebApp/CUSTOMER_REPRESENTATIVES_SUCCESS';
export const CUSTOMER_REPRESENTATIVES_ERROR =
  'local/WebApp/CUSTOMER_REPRESENTATIVES_ERROR';
export const DESTINATIONS_REQUEST = 'local/WebApp/DESTINATIONS_REQUEST';
export const DESTINATIONS_SUCCESS = 'local/WebApp/DESTINATIONS_SUCCESS';
export const DESTINATIONS_ERROR = 'local/WebApp/DESTINATIONS_ERROR';
export const EMAIL_TEMPLATES_REQUEST = 'local/WebApp/EMAIL_TEMPLATES_REQUEST';
export const EMAIL_TEMPLATES_SUCCESS = 'local/WebApp/EMAIL_TEMPLATES_SUCCESS';
export const EMAIL_TEMPLATES_ERROR = 'local/WebApp/EMAIL_TEMPLATES_ERROR';
export const EVENT_TIERS_REQUEST = 'local/WebApp/EVENT_TIERS_REQUEST';
export const EVENT_TIERS_SUCCESS = 'local/WebApp/EVENT_TIERS_SUCCESS';
export const EVENT_TIERS_ERROR = 'local/WebApp/EVENT_TIERS_ERROR';
export const PROGRAM_SETTINGS_REQUEST = 'local/WebApp/PROGRAM_SETTINGS_REQUEST';
export const PROGRAM_SETTINGS_SUCCESS = 'local/WebApp/PROGRAM_SETTINGS_SUCCESS';
export const PROGRAM_SETTINGS_ERROR = 'local/WebApp/PROGRAM_SETTINGS_ERROR';
export const PRODUCTS_REQUEST = 'local/WebApp/PRODUCTS_REQUEST';
export const PRODUCTS_SUCCESS = 'local/WebApp/PRODUCTS_SUCCESS';
export const PRODUCTS_ERROR = 'local/WebApp/PRODUCTS_ERROR';
export const REWARDS_REQUEST = 'local/WebApp/REWARDS_REQUEST';
export const REWARDS_SUCCESS = 'local/WebApp/REWARDS_SUCCESS';
export const REWARDS_ERROR = 'local/WebApp/REWARDS_ERROR';
export const REWARD_CATEGORIES_REQUEST =
  'local/WebApp/REWARD_CATEGORIES_REQUEST';
export const REWARD_CATEGORIES_SUCCESS =
  'local/WebApp/REWARD_CATEGORIES_SUCCESS';
export const REWARD_CATEGORIES_ERROR = 'local/WebApp/REWARD_CATEGORIES_ERROR';
export const REWARD_SUPPLIERS_REQUEST = 'local/WebApp/REWARD_SUPPLIERS_REQUEST';
export const REWARD_SUPPLIERS_SUCCESS = 'local/WebApp/REWARD_SUPPLIERS_SUCCESS';
export const REWARD_SUPPLIERS_ERROR = 'local/WebApp/REWARD_SUPPLIERS_ERROR';
export const ROLE_DEFINITIONS_REQUEST =
  'local/UserRoles/ROLE_DEFINITIONS_REQUEST';
export const ROLE_DEFINITIONS_SUCCESS =
  'local/UserRoles/ROLE_DEFINITIONS_SUCCESS';
export const ROLE_DEFINITIONS_ERROR = 'local/UserRoles/ROLE_DEFINITIONS_ERROR';
export const UNIT_OF_MEASURE_REQUEST = 'local/WebApp/UNIT_OF_MEASURE_REQUEST';
export const UNIT_OF_MEASURE_SUCCESS = 'local/WebApp/UNIT_OF_MEASURE_SUCCESS';
export const UNIT_OF_MEASURE_ERROR = 'local/WebApp/UNIT_OF_MEASURE_ERROR';
export const USER_POINTS_BALANCE_REQUEST =
  'local/WebApp/USER_POINTS_BALANCE_REQUEST';
export const USER_POINTS_BALANCE_SUCCESS =
  'local/WebApp/USER_POINTS_BALANCE_SUCCESS';
export const USER_POINTS_BALANCE_ERROR =
  'local/WebApp/USER_POINTS_BALANCE_ERROR';
export const USER_PROFILE_REQUEST = 'local/WebApp/USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'local/WebApp/USER_PROFILE_SUCCESS';
export const USER_PROFILE_ERROR = 'local/WebApp/USER_PROFILE_ERROR';
export const USER_STATUS_REQUEST = 'admin/WebApp/USER_STATUS_REQUEST';
export const USER_STATUS_SUCCESS = 'admin/WebApp/USER_STATUS_SUCCESS';
export const USER_STATUS_ERROR = 'admin/WebApp/USER_STATUS_ERROR';
