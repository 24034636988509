/**
 * WebApp selectors
 */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Container state
 */
const selectState = state => state.webApp || initialState;

/**
 * Menu definitions
 */
const selectMenu = () =>
  createSelector(
    selectState,
    state => state.adminMenu
  );

const selectRoutes = () =>
  createSelector(
    selectState,
    state => state.routes
  );

/**
 * Buying Periods
 */
const selectBuyingPeriods = () =>
  createSelector(
    selectState,
    state => state.buyingPeriods
  );

const selectBuyingPeriodsFormOptions = () =>
  createSelector(
    selectBuyingPeriods(),
    state => {
      return {
        requestDetails: state.requestDetails,
        data:
          state && state.data && Array.isArray(state.data)
            ? state.data.map(item => ({
                value: item.buyingPeriodId,
                label: `${item.periodName} ${
                  item.groupName ? `(${item.groupName})` : ''
                }${item.isAvailable === false ? ' - Unavailable' : ''}`,
              }))
            : state && // If it's not an array, create one. Used for 'current' Buying Period endpoint which returns a single object
              state.data &&
              new Array({
                value: state.data.buyingPeriodId,
                label: state.data.periodName,
              }),
        lastUpdated: state.lastUpdated,
      };
    }
  );

/**
 * Clients
 */
const selectClients = () =>
  createSelector(
    selectState,
    state => state.clients
  );

const clientList = (clients, depth = 0, depthIndicator = '') => {
  if (!clients || !Array.isArray(clients)) {
    return null;
  }

  if (clients.length === 0) {
    return [];
  }

  return clients.reduce((acc, item) => {
    if (item.children) {
      const newDepth = depth + 1;
      const newIndicator = `${depthIndicator}....`;
      acc.push({
        ...item,
        clientName: depthIndicator + item.clientName,
      });
      acc = acc.concat(clientList(item.children, newDepth, newIndicator));
    }
    return acc;
  }, []);
};

const selectClientsHierarchy = () =>
  createSelector(
    selectClients(),
    state => ({
      data: clientList(state.data),
      requestDetails: state.requestDetails,
      lastUpdated: state.lastUpdated,
    })
  );

const selectClientsHierarchyFormOptions = () =>
  createSelector(
    selectClientsHierarchy(),
    state => ({
      requestDetails: state.requestDetails,
      data:
        state && state.data && Array.isArray(state.data)
          ? state.data.map(client => ({
              value: client.clientId,
              label: client.clientName,
            }))
          : null,
      lastUpdated: state.lastUpdated,
    })
  );

/**
 * Cloudinary List
 */
const selectCloudinaryList = () =>
  createSelector(
    selectState,
    state => state.cloudinaryList
  );

const selectCloudinaryListFormOptions = () =>
  createSelector(
    selectCloudinaryList(),
    state => ({
      data:
        state && state.data && Array.isArray(state.data)
          ? state.data.map(asset => ({
              value: `cloudinary::${asset.public_id}`,
              label: `${asset.public_id} (${asset.width}x${asset.height})`,
            }))
          : null,
      tag: state.tag,
      lastUpdated: state.lastUpdated,
    })
  );

/**
 * Cloudinary Lori List
 */
const selectCloudinaryLoriList = () =>
  createSelector(
    selectState,
    state => state.cloudinaryLoriList
  );

const selectCloudinaryLoriListFormOptions = () =>
  createSelector(
    selectCloudinaryLoriList(),
    state => ({
      data:
        state && state.data && Array.isArray(state.data)
          ? state.data.map(asset => ({
              value: `cloudinary::${asset.publicId}`,
              label: asset.fileName,
            }))
          : null,
      tag: state.tag,
      lastUpdated: state.lastUpdated,
    })
  );

/**
 * Countries
 */
const selectCountries = () =>
  createSelector(
    selectState,
    state => state.countries
  );

const selectCountriesFormOptions = () =>
  createSelector(
    selectCountries(),
    state => ({
      requestDetails: state.requestDetails,
      data:
        state && state.data && Array.isArray(state.data)
          ? state.data.map(country => ({
              value: country.countryId,
              label: country.countryName,
            }))
          : null,
      lastUpdated: state.lastUpdated,
    })
  );

/**
 * Country Regions
 */
const selectCountryRegions = () =>
  createSelector(
    selectState,
    state => state.countryRegions
  );

/**
 * Airlines
 */
const selectAirlines = () =>
  createSelector(
    selectState,
    state => state.airlines
  );

const selectAirlinesFormOptions = () =>
  createSelector(
    selectAirlines(),
    state => ({
      requestDetails: state.requestDetails,
      data:
        state && state.data && Array.isArray(state.data)
          ? state.data.map(airline => ({
              value: airline.airlineId,
              label: airline.airlineName,
            }))
          : null,
      lastUpdated: state.lastUpdated,
    })
  );

/**
 * Customer Accounts
 */
const selectCustomerAccounts = () =>
  createSelector(
    selectState,
    state => state.customerAccounts
  );

const selectCustomerAccountsFormOptions = () =>
  createSelector(
    selectCustomerAccounts(),
    state => ({
      userId: state.userId,
      data:
        state && state.data && Array.isArray(state.data)
          ? state.data.map(item => ({
              value: item.customerAccountId,
              label: `${item.accountNumber} (${item.clientName} - ${
                item.clientCode
              })`,
            }))
          : null,
    })
  );

/**
 * AusPostProducts
 */
const selectAusPostProducts = () =>
  createSelector(
    selectState,
    state => state.ausPostProducts
  );

const selectAusPostProductsFormOptions = () =>
  createSelector(
    selectAusPostProducts(),
    state => {
      if (!state) {
        return {};
      }

      return {
        requestDetails: state.requestDetails,
        lastUpdated: state.lastUpdated,
        data:
          state && state.data && Array.isArray(state.data)
            ? state.data.map(product => ({
                value: product.productId,
                label: `${
                  !product.isStarTrack ? 'Australia Post' : 'StarTrack'
                } - ${product.productName}`,
              }))
            : null,
      };
    }
  );

/**
 * AusPostLabelLayouts
 */
const selectAusPostLabelLayouts = () =>
  createSelector(
    selectState,
    state => state.labelLayouts
  );

const selectAusPostLabelLayoutsFormOptions = () =>
  createSelector(
    selectAusPostLabelLayouts(),
    state => {
      if (!state) {
        return {};
      }

      return {
        requestDetails: state.requestDetails,
        lastUpdated: state.lastUpdated,
        data:
          state && state.data && Array.isArray(state.data)
            ? state.data.map(layout => ({
                value: `${layout.deliveryGroup}|${layout.layout}`,
                label: `${layout.deliveryGroup} - ${layout.layout}`,
              }))
            : null,
      };
    }
  );

/**
 * Customer Representatives
 */
const selectCustomerRepresentatives = () =>
  createSelector(
    selectState,
    state => state.customerRepresentatives
  );

/**
 * EmailTemplates
 */
const selectEmailTemplates = () =>
  createSelector(
    selectState,
    state => state.emailTemplates
  );

const selectEmailTemplatesFormOptions = () =>
  createSelector(
    selectEmailTemplates(),
    state => {
      if (!state) {
        return {};
      }

      return {
        requestDetails: state.requestDetails,
        data:
          state && state.data && Array.isArray(state.data)
            ? state.data.map(item => ({
                value: item.templateId,
                label: item.templateKey,
              }))
            : state &&
              state.data &&
              new Array({
                value: state.data.templateId,
                label: state.data.templateKey,
              }),
        lastUpdated: state.lastUpdated,
      };
    }
  );

/**
 * EventTiers
 */
const selectEventTiers = () =>
  createSelector(
    selectState,
    state => state.eventTiers
  );

const selectEventTiersFormOptions = () =>
  createSelector(
    selectEventTiers(),
    state => {
      if (!state) {
        return {};
      }

      return {
        requestDetails: state.requestDetails,
        data:
          state && state.data && Array.isArray(state.data)
            ? state.data.map(item => ({
                value: item.tierId,
                label: item.tierName,
              }))
            : state &&
              state.data &&
              new Array({
                value: state.data.tierId,
                label: state.data.tierName,
              }),
        lastUpdated: state.lastUpdated,
      };
    }
  );

/**
 * Role definitions
 */
const selectRoleDefinitions = () =>
  createSelector(
    selectState,
    state => state.roleDefinitions
  );

/**
 * Unit of Measure
 */
const selectUnitOfMeasure = () =>
  createSelector(
    selectState,
    state => state.unitOfMeasure
  );

const selectUnitOfMeasurePoints = () =>
  createSelector(
    selectUnitOfMeasure(),
    state => (state ? state.filter(item => item.pointsMeasure === true) : null)
  );

const selectUnitOfMeasureSales = () =>
  createSelector(
    selectUnitOfMeasure(),
    state => (state ? state.filter(item => item.pointsMeasure === false) : null)
  );

// Points UOM
const selectSettingsPointsUOM = () =>
  createSelector(
    selectUnitOfMeasure(),
    state => state && state.find(item => !!item.pointsMeasure)
  );

// Program Settings
const selectSettingsProgram = () =>
  createSelector(
    selectState,
    state => state && state.program
  );

/**
 * Products
 */
const selectProducts = () =>
  createSelector(
    selectState,
    state => state.products
  );

const selectProductsFormOptions = () =>
  createSelector(
    selectProducts(),
    state => {
      if (!state) {
        return {};
      }

      return {
        requestDetails: state.requestDetails,
        data:
          state && state.data && Array.isArray(state.data)
            ? state.data.map(item => ({
                value: item.productId,
                label: `${item.productName}${
                  item.isAvailable === false ? ' - Unavailable' : ''
                }`,
              }))
            : state &&
              state.data &&
              new Array({
                value: state.data.productId,
                label: state.data.productName,
              }),
        lastUpdated: state.lastUpdated,
      };
    }
  );

/**
 * Rewards
 */
const selectRewards = () =>
  createSelector(
    selectState,
    state => state.rewards
  );

const selectRewardsFormOptions = () =>
  createSelector(
    selectRewards(),
    state => {
      if (!state) {
        return {};
      }

      return {
        requestDetails: state.requestDetails,
        data:
          state && state.data && Array.isArray(state.data)
            ? state.data.map(item => ({
                value: item.rewardId,
                label: `${item.rewardName}${
                  item.isAvailable === false ? ' - Unavailable' : ''
                }`,
              }))
            : state &&
              state.data &&
              new Array({
                value: state.data.rewardId,
                label: state.data.rewardName,
              }),
        lastUpdated: state.lastUpdated,
      };
    }
  );

/**
 * Reward Categories
 */
const selectRewardCategories = () =>
  createSelector(
    selectState,
    state => state.rewardCategories
  );

const selectRewardCategoriesTreeData = () =>
  createSelector(
    selectRewardCategories(),
    state => ({
      data: state && state.data,
      lastUpdated: state.lastUpdated,
    })
  );

const selectRewardCategoriesFormOptions = () =>
  createSelector(
    selectRewardCategories(),
    state => {
      return {
        requestDetails: state.requestDetails,
        data:
          state && state.data && Array.isArray(state.data)
            ? state.data.map(item => ({
                value: item.rewardCategoryId,
                label: `${item.categoryName}${
                  item.isAvailable === false ? ' - Unavailable' : ''
                }`,
              }))
            : state &&
              state.data &&
              new Array({
                value: state.data.rewardCategoryId,
                label: state.data.categoryName,
              }),
        lastUpdated: state.lastUpdated,
      };
    }
  );

/**
 * Reward Suppliers
 */
const selectRewardSuppliers = () =>
  createSelector(
    selectState,
    state => state.rewardSuppliers
  );

const selectRewardSuppliersFormOptions = () =>
  createSelector(
    selectRewardSuppliers(),
    state => {
      return {
        requestDetails: state.requestDetails,
        data:
          state && state.data && Array.isArray(state.data)
            ? state.data.map(item => ({
                value: item.rewardSupplierId,
                label: `${item.supplierName}${
                  item.isAvailable === false ? ' - Unavailable' : ''
                }`,
              }))
            : state && // If it's not an array, create one. Used for 'current' Buying Period endpoint which returns a single object
              state.data &&
              new Array({
                value: state.data.rewardSupplierId,
                label: state.data.supplierName,
              }),
        lastUpdated: state.lastUpdated,
      };
    }
  );

// Sales UOMs
const selectSettingsSalesUOMs = () =>
  createSelector(
    selectUnitOfMeasure(),
    state => state && state.filter(item => !item.pointsMeasure)
  );

const selectUnitOfMeasureFormOptions = () =>
  createSelector(
    selectUnitOfMeasure(),
    state =>
      state
        ? state.map(item => ({
            value: item.unitOfMeasureId,
            label: item.name,
          }))
        : null
  );

const selectUnitOfMeasurePointsFormOptions = () =>
  createSelector(
    selectUnitOfMeasurePoints(),
    state =>
      state
        ? state.map(item => ({
            value: item.unitOfMeasureId,
            label: item.name,
          }))
        : null
  );

const selectUnitOfMeasureSalesFormOptions = () =>
  createSelector(
    selectUnitOfMeasureSales(),
    state =>
      state
        ? state.map(item => ({
            value: item.unitOfMeasureId,
            label: item.name,
          }))
        : null
  );

/**
 * User profile details
 */
const selectUserPointsBalance = () =>
  createSelector(
    selectState,
    state => state.userPointsBalance
  );

/**
 * User profile details
 */
const selectUserProfile = () =>
  createSelector(
    selectState,
    state => state.profileDetails
  );

/**
 * User status details
 */
const selectUserStatus = () =>
  createSelector(
    selectState,
    state => state.userStatusDetails
  );

/**
 * Destinations
 */
const selectDestinations = () =>
  createSelector(
    selectState,
    state => state.destinations
  );

const selectDestinationsFormOptions = () =>
  createSelector(
    selectDestinations(),
    state => ({
      requestDetails: state.requestDetails,
      data:
        state && state.data && Array.isArray(state.data)
          ? state.data.map(destination => ({
              value: destination.destinationId,
              label: destination.destinationName,
            }))
          : null,
      lastUpdated: state.lastUpdated,
    })
  );

export {
  selectState,
  selectAirlines,
  selectAirlinesFormOptions,
  selectAusPostLabelLayouts,
  selectAusPostLabelLayoutsFormOptions,
  selectAusPostProducts,
  selectAusPostProductsFormOptions,
  selectBuyingPeriods,
  selectBuyingPeriodsFormOptions,
  selectClients,
  selectClientsHierarchy,
  selectClientsHierarchyFormOptions,
  selectCloudinaryList,
  selectCloudinaryListFormOptions,
  selectCloudinaryLoriList,
  selectCloudinaryLoriListFormOptions,
  selectCountries,
  selectCountriesFormOptions,
  selectCountryRegions,
  selectCustomerAccounts,
  selectCustomerAccountsFormOptions,
  selectCustomerRepresentatives,
  selectDestinations,
  selectDestinationsFormOptions,
  selectEmailTemplates,
  selectEmailTemplatesFormOptions,
  selectEventTiers,
  selectEventTiersFormOptions,
  selectMenu,
  selectProducts,
  selectProductsFormOptions,
  selectRewards,
  selectRewardsFormOptions,
  selectRewardCategories,
  selectRewardCategoriesFormOptions,
  selectRewardCategoriesTreeData,
  selectRewardSuppliers,
  selectRewardSuppliersFormOptions,
  selectRoleDefinitions,
  selectRoutes,
  selectSettingsPointsUOM,
  selectSettingsProgram,
  selectSettingsSalesUOMs,
  selectUnitOfMeasure,
  selectUnitOfMeasurePoints,
  selectUnitOfMeasureSales,
  selectUnitOfMeasureFormOptions,
  selectUnitOfMeasurePointsFormOptions,
  selectUnitOfMeasureSalesFormOptions,
  selectUserPointsBalance,
  selectUserProfile,
  selectUserStatus,
};
