/**
 * WebApp saga
 */

import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import toast from '_platform/src/utils/toast';
import { api, apiErrorHandler } from '_platform/src/utils/request';
import { flattenDeepArrayOfObjects } from '_platform/src/utils/utilities';
import { USER_LOGOUT_REQUEST } from '_platform/src/containers/App/constants';
import {
  ADMIN_MENU_REQUEST,
  AIRLINES_REQUEST,
  AUSPOST_LABEL_LAYOUTS_REQUEST,
  AUSPOST_PRODUCTS_REQUEST,
  BUYING_PERIODS_REQUEST,
  CLIENTS_REQUEST,
  CLOUDINARY_LIST_REQUEST,
  CLOUDINARY_LORI_LIST_REQUEST,
  COUNTRIES_REQUEST,
  COUNTRY_REGIONS_REQUEST,
  CUSTOMER_ACCOUNTS_REQUEST,
  CUSTOMER_REPRESENTATIVES_REQUEST,
  DESTINATIONS_REQUEST,
  EMAIL_TEMPLATES_REQUEST,
  EVENT_TIERS_REQUEST,
  PROGRAM_SETTINGS_REQUEST,
  PRODUCTS_REQUEST,
  REWARDS_REQUEST,
  REWARD_CATEGORIES_REQUEST,
  REWARD_SUPPLIERS_REQUEST,
  ROLE_DEFINITIONS_REQUEST,
  UNIT_OF_MEASURE_REQUEST,
  USER_POINTS_BALANCE_REQUEST,
  USER_PROFILE_REQUEST,
  USER_STATUS_REQUEST,
} from './constants';
import {
  adminMenuSuccess,
  adminMenuError,
  adminMenuUpdateRoutes,
  airlinesSuccess,
  airlinesError,
  ausPostLabelLayoutsSuccess,
  ausPostLabelLayoutsError,
  ausPostProductsSuccess,
  ausPostProductsError,
  buyingPeriodsSuccess,
  buyingPeriodsError,
  clientsSuccess,
  clientsError,
  cloudinaryListSuccess,
  cloudinaryListError,
  cloudinaryLoriListSuccess,
  cloudinaryLoriListError,
  countriesSuccess,
  countriesError,
  countryRegionsSuccess,
  countryRegionsError,
  customerAccountsSuccess,
  customerAccountsError,
  customerRepresentativesSuccess,
  customerRepresentativesError,
  destinationsSuccess,
  destinationsError,
  emailTemplatesSuccess,
  emailTemplatesError,
  eventTiersSuccess,
  eventTiersError,
  programSettingsSuccess,
  programSettingsError,
  productsSuccess,
  productsError,
  rewardsSuccess,
  rewardsError,
  rewardCategoriesSuccess,
  rewardCategoriesError,
  rewardSuppliersSuccess,
  rewardSuppliersError,
  roleDefinitionsSuccess,
  roleDefinitionsError,
  unitOfMeasureSuccess,
  unitOfMeasureError,
  userPointsBalanceSuccess,
  userPointsBalanceError,
  userProfileSuccess,
  userProfileError,
  userStatusSuccess,
  userStatusError,
} from './actions';

/**
 * Request Menu
 */
export function* requestMenu() {
  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get('/Menu/v1/Private', {
        description: 'Admin menu',
      })
    );

    yield put(adminMenuSuccess(response.data));

    // Routes
    const routes =
      response.data && response.data.length > 0
        ? flattenDeepArrayOfObjects(response.data, 'children')
        : null;

    yield put(adminMenuUpdateRoutes(routes));

    if (routes) {
      localStorage.setItem('routes', JSON.stringify(routes));
    } else {
      localStorage.removeItem('routes');
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(adminMenuError(error));
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Buying Periods
 */
export function* requestBuyingPeriods(params) {
  const { clientId, current } = params;

  let endpoint = '/BuyingPeriod/v1';

  if (clientId && current) {
    endpoint += `/Current/Client/${clientId}`;
  } else if (current) {
    endpoint += '/Current';
  } else if (clientId) {
    endpoint += `/${clientId}`;
  }

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'Buying Periods list',
      })
    );

    yield put(
      buyingPeriodsSuccess(
        response.data,
        {
          clientId,
          current,
        },
        Math.floor(Date.now() / 1000)
      )
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      // Reset the buying periods back to undefined in order to re-fetch after login
      yield put(buyingPeriodsError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Clients
 *
 * Client GET endpoints:
 *
 * /Client/v1/                      - Get all public and available clients, children are displayed at the same level as parents
 * /Client/v1/Parents               - Get all public and available parent clients (children are not returned in the array or in children prop)
 * /Client/v1/Hierarchy             - Get all public and available clients as a hierarchy, including children. Requesting by ID is optional (/{id})
 * /Client/v1/Private               - Get all available clients, in a flat array
 * /Client/v1/Private/Hierarchy     - Get all available clients as a hierarchy
 * /Client/v1/Private/Code/{code}   - Get available client by their code. Single result only.
 * /Client/v1/Private/User          - Get all available clients assigned to the currently logged in user. NOTE: This will usually only return parents unless the user is assigned to children. It is not recommended to assign the user to children, see below.
 * /Client/v1/Private/UserHierarchy - Get all available clients assigned to the currently logged in user, and their children. NOTE: Do not assign the user to the children as they will be then returned as children, as well as at the top level
 * /Client/v1/Private/All           - Get all clients, ignoring if they are available or not. Flat array.
 */
export function* requestClients(params) {
  const { requestType, value } = params;

  let endpoint = '/Client/v1';
  switch (requestType) {
    case 'all':
      endpoint += '/Private/All';
      break;
    case 'code':
      endpoint += `/Private/Code/${value}`;
      break;
    case 'privateHierarchy':
      endpoint += '/Private/Hierarchy';
      break;
    case 'user':
      endpoint += '/Private/User';
      break;
    case 'userHierarchy':
    default:
      endpoint += '/Private/UserHierarchy';
      break;
  }

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'Clients list',
      })
    );

    yield put(
      clientsSuccess(
        response.data,
        {
          requestType: requestType || 'userHierarchy',
          value,
        },
        Math.floor(Date.now() / 1000)
      )
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(clientsError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Cloudinary List
 */
export function* requestCloudinaryList(params) {
  const { tag, newImages } = params;

  const normalisedNewImages =
    newImages &&
    Array.isArray(newImages) &&
    newImages.length > 0 &&
    newImages.map(image => ({
      public_id: image.public_id,
      version: image.version,
      format: image.format,
      width: image.width,
      height: image.height,
      type: image.type,
      created_at: image.created_at,
      existing: image.existing,
    }));

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(
        `https://res.cloudinary.com/incremental/image/list/${tag}.json?version=${Math.floor(
          Date.now() / 1000
        )}`, // version query string will bust local cache, Cloudinary however caches for one minute
        {
          description: 'Cloudinary image list',
          // Remove the default Authorization header from the request to avoid CORS errors
          // Have to do it this way, as Axios instances are polluting headers between each-other
          // Issue may be fixed in the future:
          // https://github.com/axios/axios/pull/1395/
          // Need to check if the immutable headers were introduced, how that will impact on
          // setting/removing the platform Authorization header via utils/request (apiSetAuthorizationToken)
          // as tokens are refreshed frequently
          transformRequest: [
            (data, headers) => {
              delete headers.Authorization;
              return data;
            },
          ],
        }
      )
    );

    // If newImages is specified, check that the response contains the new images
    // If not, prepend to list
    const responseData =
      response.data &&
      response.data.resources &&
      Array.isArray(response.data.resources) &&
      response.data.resources.length > 0
        ? response.data.resources
        : null;

    let prependNewImages = [];
    if (normalisedNewImages) {
      // If there are no images in the response, prepend all new images
      if (!responseData) {
        prependNewImages = normalisedNewImages;
      } else {
        // Otherwise, find which images are not in the response and only prepend them
        prependNewImages = normalisedNewImages.filter(
          newImage =>
            !response.data.resources.some(
              existingImage => newImage.public_id === existingImage.public_id
            )
        );
      }
    }

    // Merge response data with the newImages if necessary
    const imageList = responseData
      ? [...prependNewImages, ...responseData]
      : prependNewImages;

    yield put(
      cloudinaryListSuccess(imageList, tag, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(cloudinaryListError(error));
    } else if (normalisedNewImages) {
      // If the newImages exist, add them and suffix the tag with '-error'
      // so it can be re-fetched in the future.
      yield put(
        cloudinaryListSuccess(
          normalisedNewImages,
          `${tag}-error`,
          Math.floor(Date.now() / 1000)
        )
      );
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Cloudinary Lori List
 */
export function* requestCloudinaryLoriList(params) {
  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(`/Cloudinary/v1/${params.tag ? params.tag : ''}`, {
        description: 'Cloudinary list',
      })
    );

    yield put(
      cloudinaryLoriListSuccess(
        response.data,
        params.tag,
        Math.floor(Date.now() / 1000)
      )
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(cloudinaryLoriListError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Countries
 *
 */
export function* requestCountries() {
  const endpoint = '/Country/v1/Private/All';

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'Countries list',
      })
    );

    yield put(countriesSuccess(response.data, Math.floor(Date.now() / 1000)));
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(countriesError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request CountryRegions
 *
 */
export function* requestCountryRegions() {
  const endpoint = '/Country/v1/Private/true';

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'Country Regions list',
      })
    );

    yield put(
      countryRegionsSuccess(response.data, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(countryRegionsError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Airlines
 *
 */
export function* requestAirlines() {
  const endpoint = '/Airline/v1/Private/All';

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'Airlines list',
      })
    );

    yield put(airlinesSuccess(response.data, Math.floor(Date.now() / 1000)));
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(airlinesError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Destinations
 *
 */
export function* requestDestinations() {
  const endpoint = '/Destination/v1/Private';

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'Destinations list',
      })
    );

    yield put(
      destinationsSuccess(response.data, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(destinationsError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request AusPostProducts
 *
 */
export function* requestAusPostProducts() {
  const endpoint = '/Logistics/v1/Private/AusPost/Products';

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'AusPostProducts list',
      })
    );

    yield put(
      ausPostProductsSuccess(response.data, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(ausPostProductsError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request AusPostLabelLayouts
 *
 */
export function* requestAusPostLabelLayouts() {
  const endpoint = '/Logistics/v1/Private/AusPost/LabelLayouts';

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'AusPostLabelLayouts list',
      })
    );

    yield put(
      ausPostLabelLayoutsSuccess(response.data, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(ausPostLabelLayoutsError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Customer Accounts
 */
export function* requestCustomerAccounts(params) {
  const { userId } = params;

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(`/Customer/v1/Private/Accounts/${userId}`, {
        description: 'Customer accounts',
      })
    );

    yield put(customerAccountsSuccess(response.data, userId));
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      // Reset the buying periods back to undefined in order to re-fetch after login
      yield put(customerAccountsError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Customer Representatives
 */
export function* requestCustomerRepresentatives(params) {
  const { userId } = params;

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(`/Customer/v1/Private/Representatives/${userId}`, {
        description: 'Customer accounts',
      })
    );

    yield put(customerRepresentativesSuccess(response.data, userId));
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      // Reset the buying periods back to undefined in order to re-fetch after login
      yield put(customerRepresentativesError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request program settings
 */
export function* requestProgramSettings() {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get('/Program/v1', {
        description: 'Program Settings',
      })
    );

    yield put(
      programSettingsSuccess(response.data || {}, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      // Reset the clients back to undefined in order to re-fetch after login
      yield put(programSettingsError());
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request role definitions
 */
export function* requestRoleDefinitions() {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get('/Role/v1/Private', {
        description: 'Role definitions',
      })
    );

    yield put(
      roleDefinitionsSuccess(response.data, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      // Reset the clients back to undefined in order to re-fetch after login
      yield put(roleDefinitionsError());
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request Unit of Measure
 */
export function* requestUnitOfMeasure() {
  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get('/UnitOfMeasure/v1', {
        description: 'Unit of Measure list',
      })
    );

    yield put(unitOfMeasureSuccess(response.data));
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      // Reset the values back to initial state in order to re-fetch after login
      yield put(unitOfMeasureError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request points balance
 */
export function* requestUserPointsBalance(value) {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get(`/User/v1/Private/Balance/${value.userId}`, {
        description: 'Points balance',
      })
    );

    yield put(userPointsBalanceSuccess(response.data, value.userId));
  } catch (err) {
    const error = apiErrorHandler(err);
    const errorMsg =
      error.message ||
      (err.response.data &&
      err.response.data.userId && // 400, invalid userId
        'Could not retrieve points balance');

    if (error.status === 401) {
      // Reset the definitions back to undefined in order to re-fetch after login
      yield put(userPointsBalanceError(error, value.userId));
    } else {
      yield call(toast, 'error', errorMsg);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request profile
 */
export function* requestUserProfile(value) {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get(`/User/v1/Private/Profile/${value.userId}`, {
        description: 'Profile details',
      })
    );

    yield put(userProfileSuccess(response.data, response.data.userId));
  } catch (err) {
    const error = apiErrorHandler(err);
    const errorMsg =
      error.message ||
      (err.response.data &&
      err.response.data.userId && // 400, invalid userId
        'Could not retrieve user details');

    if (error.status === 401) {
      // Reset the definitions back to undefined in order to re-fetch after login
      yield put(userProfileError(error, value.userId));
    } else {
      yield call(toast, 'error', errorMsg);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request user status
 */
export function* requestUserStatus(value) {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get(`/User/v1/Private/Status/${value.userId}`, {
        description: 'Status details',
      })
    );

    yield put(userStatusSuccess(response.data, response.data.userId));
  } catch (err) {
    const error = apiErrorHandler(err);
    const errorMsg =
      error.message ||
      (err.response.data &&
      err.response.data.userId && // 400, invalid userId
        "Could not retrieve the user's status details");

    if (error.status === 401) {
      // Reset the definitions back to undefined in order to re-fetch after login
      yield put(userStatusError(error, value.userId));
    } else {
      yield call(toast, 'error', errorMsg);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * User Logout Listener
 */
export function* userLogoutListener() {
  try {
    yield localStorage.removeItem('routes');

    yield delay(200);
    yield put(requestMenu(true));
  } catch (err) {
    // console.warn('Error:', err);
  }
}

/**
 * Request RewardSuppliers
 *
 */
export function* requestRewardSuppliers() {
  const endpoint = '/RewardSupplier/v1/Private/All';

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'Reward Suppliers list',
      })
    );

    yield put(
      rewardSuppliersSuccess(response.data, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(rewardSuppliersError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request reward categories
 */
export function* requestRewardCategories() {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get(`/RewardCategory/v1/Private/All`, {
        description: 'Reward categories',
      })
    );

    yield put(
      rewardCategoriesSuccess(response.data, Math.floor(Date.now() / 1000))
    );
  } catch (err) {
    const error = apiErrorHandler(err);
    const errorMsg =
      error.message ||
      (err.response.data && 'Could not retrieve the reward categories');

    if (error.status === 401) {
      // Reset the definitions back to undefined in order to re-fetch after login
      yield put(rewardCategoriesError(error));
    } else {
      yield call(toast, 'error', errorMsg);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request products
 */
export function* requestProducts() {
  try {
    yield put(showLoading());
    const response = yield call(() =>
      api.get(`/Product/v1/Private/All`, {
        description: 'Products',
      })
    );

    yield put(productsSuccess(response.data, Math.floor(Date.now() / 1000)));
  } catch (err) {
    const error = apiErrorHandler(err);
    const errorMsg =
      error.message || (err.response.data && 'Could not retrieve the products');

    if (error.status === 401) {
      // Reset back to undefined in order to re-fetch after login
      yield put(productsError(error, true));
    } else {
      yield call(toast, 'error', errorMsg);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request rewards
 */
export function* requestRewards(params) {
  const { requestType, value } = params;
  let endpoint = '/Reward/v1/Private';

  if (requestType === 'all') {
    endpoint += '/All';
  }

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'Rewards list',
      })
    );

    yield put(
      rewardsSuccess(
        response.data,
        {
          requestType: requestType,
          value,
        },
        Math.floor(Date.now() / 1000)
      )
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(rewardsError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request emailTemplates
 */
export function* requestEmailTemplates(params) {
  const { requestType, value } = params;
  const endpoint = '/EmailTemplate/v1/Private/Summaries';

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'EmailTemplates list',
      })
    );

    yield put(
      emailTemplatesSuccess(
        response.data,
        {
          requestType: requestType,
          value,
        },
        Math.floor(Date.now() / 1000)
      )
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(emailTemplatesError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Request eventTiers
 */
export function* requestEventTiers(params) {
  const { requestType, value } = params;
  const endpoint = '/EventTier/v1/Private';

  try {
    yield put(showLoading());

    const response = yield call(() =>
      api.get(endpoint, {
        description: 'EventTiers list',
      })
    );

    yield put(
      eventTiersSuccess(
        response.data,
        {
          requestType: requestType,
          value,
        },
        Math.floor(Date.now() / 1000)
      )
    );
  } catch (err) {
    const error = apiErrorHandler(err);

    if (error.status === 401) {
      yield put(eventTiersError(error));
    } else {
      yield call(toast, 'error', error.message);
    }
  } finally {
    yield put(hideLoading());
  }
}

/**
 * Default saga managers the watcher lifecycle
 */
export default function* rootSaga() {
  // Specify the constant to watch and the saga generator function to call when one is triggered
  // It returns a task descriptor (just like a fork) so we can continue execution, and will be
  // automatically cancelled on unmount (unless the mode is set to DAEMON)
  // Specify as many yields (watchers) here as necessary
  yield takeLatest(ADMIN_MENU_REQUEST, requestMenu);
  yield takeLatest(AIRLINES_REQUEST, requestAirlines);
  yield takeLatest(AUSPOST_LABEL_LAYOUTS_REQUEST, requestAusPostLabelLayouts);
  yield takeLatest(AUSPOST_PRODUCTS_REQUEST, requestAusPostProducts);
  yield takeLatest(BUYING_PERIODS_REQUEST, requestBuyingPeriods);
  yield takeLatest(CLIENTS_REQUEST, requestClients);
  yield takeLatest(CLOUDINARY_LIST_REQUEST, requestCloudinaryList);
  yield takeLatest(CLOUDINARY_LORI_LIST_REQUEST, requestCloudinaryLoriList);
  yield takeLatest(COUNTRIES_REQUEST, requestCountries);
  yield takeLatest(COUNTRY_REGIONS_REQUEST, requestCountryRegions);
  yield takeLatest(CUSTOMER_ACCOUNTS_REQUEST, requestCustomerAccounts);
  yield takeLatest(
    CUSTOMER_REPRESENTATIVES_REQUEST,
    requestCustomerRepresentatives
  );
  yield takeLatest(DESTINATIONS_REQUEST, requestDestinations);
  yield takeLatest(EMAIL_TEMPLATES_REQUEST, requestEmailTemplates);
  yield takeLatest(EVENT_TIERS_REQUEST, requestEventTiers);
  yield takeLatest(PROGRAM_SETTINGS_REQUEST, requestProgramSettings);
  yield takeLatest(PRODUCTS_REQUEST, requestProducts);
  yield takeLatest(REWARDS_REQUEST, requestRewards);
  yield takeLatest(REWARD_CATEGORIES_REQUEST, requestRewardCategories);
  yield takeLatest(REWARD_SUPPLIERS_REQUEST, requestRewardSuppliers);
  yield takeLatest(ROLE_DEFINITIONS_REQUEST, requestRoleDefinitions);
  yield takeLatest(UNIT_OF_MEASURE_REQUEST, requestUnitOfMeasure);
  yield takeLatest(USER_LOGOUT_REQUEST, userLogoutListener);
  yield takeLatest(USER_POINTS_BALANCE_REQUEST, requestUserPointsBalance);
  yield takeLatest(USER_PROFILE_REQUEST, requestUserProfile);
  yield takeLatest(USER_STATUS_REQUEST, requestUserStatus);
}
